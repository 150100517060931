import React from "react"
import { Themed } from "theme-ui"

/**
 * Change the content to add your own bio
 */

export default function Bio() {
  return (
    <>
      My name is <Themed.a href="https://scholar.google.com/citations?user=SxUHsFkAAAAJ&hl=en">Mohammad K. Dashti</Themed.a>
      {` `}
      <br />
      I am a <Themed.a href="https://www.usenix.org/system/files/1311_05-08_mickens.pdf">systems</Themed.a> person.
      {` `}
      <br />
      And I will occasionally dereference NULL pointers here.
    </>
  )
}
